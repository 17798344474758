
import { Component, Vue } from 'vue-property-decorator'
import { CustomerType } from '@/utils/enum'
import { Info } from '@/types/customer'

@Component({
  name: 'CustomerDetail'
})
export default class extends Vue {
  private info: Info = {
    customerName: '',
    customerType: '',
    customerStatus: '',
    remark: '',
    province: '',
    city: '',
    area: '',
    address: '',
    validDate: '',
    contactList: [],
    adminAccount: '',
    adminPassword: '',
    versionId: '',
    versionName: '',
    isImgHtml: ''
  }

  customerType = CustomerType

  created () {
    this.getData(this.$route.params.id)
  }

  getData (id: string) {
    this.$axios.get<Info>(this.$apis.customer.customerDetails, { customerId: id })
      .then((res) => {
        this.info = {
          customerName: res.customerName,
          customerType: res.customerType,
          customerStatus: res.customerStatus,
          remark: res.remark,
          province: res.province,
          city: res.city,
          area: res.area,
          address: res.address,
          validDate: res.validDate,
          contactList: [],
          adminAccount: res.adminAccount,
          adminPassword: res.adminPassword,
          versionId: res.versionId,
          versionName: res.versionName,
          customerId: res.customerId,
          isImgHtml: ''
        }
        if (res.contactList) {
          res.contactList.map((item) => {
            this.info.contactList.push(Object.assign({}, item, { isEdit: false }))
          })
        }
      })
  }
}
